<template>
  <div class="card card-custom gutter-b">
    <div class="col-md-12">
      <b-form>
        <div class="card-header">
          <h4
            style="font-weight: bold; font-size: 18px; line-height: 27px; color: #464E5F;"
          >
            Notifikasi
          </h4>
        </div>
        <div class="card-body">
          <template v-for="(notification, i) in notifications">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-6" v-bind:key="i">
              <!--begin::Text-->
              <div class="d-flex flex-column">
                <a
                  href="#"
                  class="text-dark mb-1 font-size-lg font-weight-bold"
                >
                  {{ notification.message }}
                </a>
                <span class="text-muted">
                  {{ notification.created_at }}
                </span>
                <div class="separator separator-solid mt-8 mb-5"></div>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { handleErrors } from "@/core/appUtil/util";
let token = "";

export default {
  name: "Notifikasi",
  data() {
    return {
      notifications: []
    };
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/notifikasi`), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token
        }
      })
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          console.log(items);
          this.notifications = items.data;
          this.loading = false;
        });
    });
  }
};
</script>
